import React, { useCallback, useMemo, useState } from "react";
import {
  IconButton,
  Icon,
  Menu,
  Box,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import RawMaterialRepository from "layouts/raw-material/repository/RawMaterialRepository";
import MDBox from "components/MDBox";

const ActionMenu = (props) => {
  const { handleEditAction, handleDeleteAction, row, handleClose } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = useCallback(
    (event, userId, categoryName) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
    handleClose();
  }, [setAnchorEl, handleClose]);

  const handleEditItemClick = () => {
    handleCloseMenu(); // Close the menu
    handleEditAction(row);
  };

  const handleDeleteItemClick = () => {
    handleCloseMenu(); // Close the menu
    handleDeleteAction(row);
  };
  return (
    <>
      <IconButton
        size="small"
        disableRipple
        color="inherit"
        aria-controls="notification-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={(e) => handleOpenMenu(e, row?._id, row.name)}
      >
        <MoreVertRoundedIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorReference={anchorEl ? "anchorEl" : null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        <Box display="flex" flexDirection="column">
          <MDButton variant="text" color="dark" onClick={handleEditItemClick}>
            <Icon>edit</Icon>
            &nbsp;edit
          </MDButton>
          <MDButton variant="text" color="error" onClick={handleDeleteItemClick}>
            <Icon>delete</Icon>
            &nbsp;delete
          </MDButton>
        </Box>
      </Menu>
    </>
  );
};

const useTables = (rowData, setOpenAlert, setAlertMessage) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isAddCategoryDialogOpen, setIsAddCategoryDialogOpen] = useState(false);
  const [categoryName, setCategoryName] = useState(""); // Edit dialog category name state
  const [categoryNameValues, setCategoryNameValues] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);

  const handleEditAction = useCallback((row) => {
    setSelectedRow(row);
    setCategoryName(categoryNameValues[row._id] || row.name); // Set category name for editing
    setIsAddCategoryDialogOpen(true);
  }, [categoryNameValues]);

  const handleCloseEditDialog = useCallback(() => {
    setSelectedRow(null);
    setIsAddCategoryDialogOpen(false);
    setCategoryName(""); // Clear category name when closing the dialog
  }, []);

  const handleDeleteAction = useCallback(async (row) => {
    try {
      await RawMaterialRepository.deleteCategory(row._id);
      setOpenAlert(true);
      setAlertMessage("Raw Material Category Deletion Successful");
      queryClient.invalidateQueries("rawmaterialscategories");
    } catch (error) {
      setOpenAlert(true);
      setAlertMessage("Raw Material Category Deletion Unsuccessful");
    }
  }, [queryClient, setOpenAlert, setAlertMessage]);


  const handleUpdateCategory = useCallback(async (id) => {
    try {
      await RawMaterialRepository.updateCategory(id, categoryName);
      setOpenAlert(true);
      setAlertMessage("Raw Material Category Updated Successfully");
      queryClient.invalidateQueries("rawmaterialscategories");
      handleCloseEditDialog();
    } catch (error) {
      setOpenAlert(true);
      setAlertMessage("Raw Material Category Updated Unsuccessful.");
    }
  }, [queryClient, setOpenAlert, setAlertMessage, categoryName, handleCloseEditDialog]);
  const columns = [
    { Header: "Raw Material Category name", accessor: "rawMaterialName", align: "center" },
    { Header: "action", accessor: "action", width: "50%", align: "center" },
  ];

  const rows = useMemo(() => {
    return rowData?.map((row) => ({
      rawMaterialName: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {row.name}
        </MDTypography>
      ),
      action: (
        <>
          <Dialog open={isAddCategoryDialogOpen && selectedRow?._id === row._id} onClose={handleCloseEditDialog} fullWidth>
            <DialogTitle>Edit Category</DialogTitle>

            <DialogContent>
              <TextField
                label="Category Name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                style={{ marginTop: 10 }}
                fullWidth
                variant="outlined"
              />
            </DialogContent>
            <MDBox mt={2} mb={2} mx={2} display="flex" justifyContent="flex-end">
              <MDButton variant="outlined" color="error" onClick={handleCloseEditDialog} style={{ marginRight: '8px' }}>
                Cancel
              </MDButton>
              <MDButton
                variant="gradient"
                color="success"
                onClick={() => {
                  handleUpdateCategory(row._id);
                }}
              >
                Edit
              </MDButton>
            </MDBox>
          </Dialog>
          <ActionMenu
            handleEditAction={() => handleEditAction(row)}
            handleDeleteAction={() => handleDeleteAction(row)}
            row={row}
            handleClose={handleCloseEditDialog}
          />
        </>
      ),
    }));
  }, [rowData, isAddCategoryDialogOpen, categoryName, categoryNameValues, handleCloseEditDialog, handleUpdateCategory, handleEditAction, handleDeleteAction, setSelectedRow]);


  return { rows, columns };
};

export default useTables;
