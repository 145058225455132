import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton, Icon, Menu, Box } from "@mui/material";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import RawMaterialRepository from "layouts/raw-material/repository/RawMaterialRepository";
import FormDialog from "components/DilaogBox/FormDialog";
import AlertBox from "components/AlertBox/AlertBox";

const ActionMenu = (props) => {
    const { handleEditAction, handleDeleteAction } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <>
            <IconButton
                size="small"
                disableRipple
                color="inherit"
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
            >
                <MoreVertRoundedIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorReference={anchorEl ? "anchorEl" : null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ mt: 2 }}
            >
                <Box display="flex" flexDirection="column">
                    <MDButton variant="text" color="dark" onClick={handleEditAction}>
                        <Icon>edit</Icon>
                        &nbsp;edit
                    </MDButton>
                    <MDButton variant="text" color="error" onClick={handleDeleteAction}>
                        <Icon>delete</Icon>
                        &nbsp;delete
                    </MDButton>
                </Box>
            </Menu>
        </>
    );
}



const CustomDialog = (props) => {
    const {row ,handleOpenAlert} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [rawMaterialId, setRawMaterialId] = useState()
    const [totalQuantity, setTotalQuantity] = useState()
    const [type, setType] = useState();


    const handleOpenDialog = useCallback((event, type, id, quantity) => {
        if (type === "buy") {
            setType("buy");
        } else {
            setType("use");
        }
        setRawMaterialId(id);
        setTotalQuantity(quantity);
        setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseDialog = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <>
            <FormDialog
                anchorEl={anchorEl}
                anchorReference={anchorEl ? "anchorEl" : null}
                handleClose={handleCloseDialog}
                type={type}
                rawMaterialId={rawMaterialId}
                handleOpenAlert={handleOpenAlert}
                totalQuantity={totalQuantity}
            />
            <MDButton
                variant="contained"
                size="small"
                color="success"
                style={{ marginRight: 10 }}
                onClick={(e) => handleOpenDialog(e, "buy", row._id, row.total_quantity)}
            >
                <ShoppingBasketIcon />
                &nbsp;BUY
            </MDButton>
            <MDButton
                variant="contained"
                size="small"
                color="error"
                onClick={(e) => handleOpenDialog(e, "use", row._id, row.total_quantity)}
            >
                <StorefrontIcon />
                &nbsp;USE
            </MDButton>
        </>
    );
}

const useTables = (rowData, setOpenAlert, setAlertMessage) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [type, setType] = useState();
    const [openActionAlert, setOpenActionAlert] = useState(false)
    const [alertActionMessage, setAlertActionMessage] = useState("")
    const [rawMaterialId, setRawMaterialId] = useState()
    const [totalQuantity, setTotalQuantity] = useState()


    const handleOpenAlert = () => {
        setOpenActionAlert(true)
        setAlertActionMessage("Action Successful")
        queryClient.invalidateQueries("rawmaterials");
    }


    const handleDialogBoxClose = () => {
        setOpenDialog(false);
    };
    ;

    const handleEditAction = useCallback((id) => {
        navigate("/raw-material/edit", { state: { id: id } });
    }, [navigate, selectedUserId]);

    const handelViewAction = useCallback(
        (rawMaterialId) => {
            navigate("/raw-material/view", { state: { id: rawMaterialId } });
        },
        [navigate]
    );


    const handelViewHistoryAction = useCallback(
        (rawMaterialId, name, type) => {
            navigate("/raw-material/history", {
                state: { id: rawMaterialId, rawMaterialName: name, quantityType: type },
            });
        },
        [navigate]
    );


    const deleteRawMaterial = useCallback(async (id) => {
        return await RawMaterialRepository.delete(id);
    }, []);


    //User form hook for deletion
    const { mutateAsync, isLoading: isMutate } = useMutation(deleteRawMaterial, {
        onSuccess: (response) => {
            setOpenAlert(true)
            setAlertMessage("Raw Material Deletion Successful")
        },
        onError: (err) => {
            setOpenAlert(true)
            setAlertMessage("Raw Material Deletion Unsucessful")
        },
    });

    const remove = useCallback(async (id) => {
        await mutateAsync(id);
        queryClient.invalidateQueries("rawmaterials");
    }, [mutateAsync, queryClient, selectedUserId]);


    const handelDeleteAction = useCallback((id) => {
        remove(id);
    }, [remove]);


    const Row = styled.div`
      display:flex;
      flex-direction:row;
      justify-content:space-between;
`;

    const columns = [
        { Header: "Raw Material name", accessor: "rawMaterialName", align: "center" },
        { Header: "Raw Material Quantiy", accessor: "rawMaterialQuantity", align: "center" },
        { Header: "Buy/Use", accessor: "buyUse", align: "center" },
        { Header: "View History", accessor: "rawMaterialHistory", width: "10%", align: "center" },
        { Header: "View Details", accessor: "rawMaterial", width: "10%", align: "center" },
        { Header: "action", accessor: "action", width: "10%", align: "center" },
    ];


    const rows = useMemo(() => {
        return rowData?.map((row) => ({
            rawMaterialName: (
                <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                >
                    {row.raw_material_name}
                </MDTypography>
            ),
            rawMaterialQuantity: (
                <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color={
                        row.total_quantity <= row.raw_material_min_quantity
                            ? "error"
                            : "text"
                    }
                    fontWeight="medium"
                >
                    {row?.total_quantity
                        ? `${row.total_quantity} (${row.quantity_type})`
                        : `0 (${row.quantity_type})`}
                </MDTypography>
            ),
            buyUse: (
                <>
                    <CustomDialog row={row} handleOpenAlert={handleOpenAlert} />
                    {/* <FormDialog
                        open={openDialog}
                        handleClose={handleDialogBoxClose}
                        type={type}
                        rawMaterialId={rawMaterialId}
                        handleOpenAlert={handleOpenAlert}
                        totalQuantity={totalQuantity}
                    />
                    <MDButton
                        variant="contained"
                        size="small"
                        color="success"
                        style={{ marginRight: 10 }}
                        onClick={() => handleAction("buy", row._id, row.total_quantity)}
                    >
                        <ShoppingBasketIcon />
                        &nbsp;BUY
                    </MDButton>
                    <MDButton
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => handleAction("use", row._id, row.total_quantity)}
                    >
                        <StorefrontIcon />
                        &nbsp;USE
                    </MDButton> */}
                </>
            ),
            action: (
                <ActionMenu
                    handleEditAction={() => handleEditAction(row._id)}
                    handleDeleteAction={() => handelDeleteAction(row._id)}
                />
            ),
            rawMaterial: (
                <>
                    <AlertBox
                        openAlert={openActionAlert}
                        alertMessage={alertActionMessage}
                        setOpenAlert={setOpenActionAlert}
                    />
                    <MDButton
                        variant="text"
                        color="dark"
                        onClick={() => handelViewAction(row._id)}
                    >
                        <Icon>people</Icon>
                        &nbsp;view details
                    </MDButton>
                </>
            ),
            rawMaterialHistory: (
                <MDButton
                    variant="text"
                    color="dark"
                    onClick={() =>
                        handelViewHistoryAction(
                            row._id,
                            row.raw_material_name,
                            row.quantity_type
                        )
                    }
                >
                    <Icon>people</Icon>
                    &nbsp;view history
                </MDButton>
            ),
        }));
    }, [
        rowData,
        handleEditAction,
        handelDeleteAction,
    ]);

    return { rows, columns }
}



export default useTables;
